<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker v-model="state.optionFields.shipDateFrom.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model="state.optionFields.shipDateTo.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Item Type</label>
                <select class='form-control' v-model="state.optionFields.curbTypes.val">
                    <option value='0'>All</option>
                    <option v-for='[id, curbType] in cache.curbType.CURB_TYPES_HISTORICAL' v-bind:key='id' :value="id">{{ curbType }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Item</label>
                <Typeahead cdi_type='curb' v-bind:ID.sync='state.optionFields.itemId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Old</label>
                <Typeahead cdi_type='part' v-bind:ID.sync='state.optionFields.oldId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>New</label>
                <Typeahead cdi_type='part' v-bind:ID.sync='state.optionFields.newId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Distributor</label>
                <Typeahead cdi_type='user' v-bind:ID.sync='state.optionFields.distributorId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Company</label>
                <Typeahead ref="company" cdi_type='company' v-bind:ID.sync='state.optionFields.companyId.val' />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.turretReady.val"> Turret Ready</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.conforming.val"> Conforming</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="state.optionFields.hasEstimator.val"> Has Estimator</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type="checkbox" :true-value="1" :false-value="0" v-model="state.optionFields.hasFreight.val"> Has Freight</label>
            </div>

            <div class="col-xs-12 toggle_target" v-show="!toggleHide"><div class="filter_separator">Exclude</div></div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.remake.val"> Remakes</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.warehouse.val" > Warehouse</label>
            </div>

            <div class="col-xs-12 toggle_target" v-show="!toggleHide"><div class="filter_separator">Costing Fields</div></div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox value='1' v-model="state.optionFields.labor.val"> Labor
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.materials.val"> Materials
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.freight.val"> Freight
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.misc.val"> Misc
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.cogs.val"> COGS
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.grossProfit.val"> Gross Profit
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.estimatedLabor.val"> Estimated Labor
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox  :true-value="1" :false-value="0" v-model="state.optionFields.estimatedFreight.val"> Estimated Freight
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.estimatedMaterials.val"> Estimated Materials
                </label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label>
                    <input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.estimatedExtras.val"> Estimated Extras
                </label>
            </div>

            <div class='col-xs-12' v-show="!toggleHide"><div class="filter_separator" v-show="!toggleHide">Item Type <small>(All if none are checked)</small></div></div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide" v-for="[curbTypeHistoricalId, curbTypeHistorical] in cache.curbType.CURB_TYPES_HISTORICAL" v-bind:key="curbTypeHistoricalId" >
                <label ><input type=checkbox :true-value="1" v-model="state.optionFields.curbTypesCheck.val" :false-value=null :value="curbTypeHistoricalId"> {{ curbTypeHistorical }}</label>
            </div>

            <div class="col-xs-12">
                <button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import curbType from '@/cache/curbType.cache.js';
    import DatePicker from "../utils/DatePicker";
    import Typeahead from "@/components/utils/typeaheads/Typeahead"

    export default {
        name: "Options",
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    curbType
                }
            }
        },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.company.object = '';
                }
            },
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>