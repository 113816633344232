<template>
    <div>
        <h1>Job Costing</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./jobcosting_opt";
    import GroupBy from "./utils/GroupBy";
    import Results from "./utils/Results";
    export default {
        name: 'jobcosting',
        components: {Results, GroupBy, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    shipDateFrom: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    shipDateTo: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    itemType: {val: 0, qsField: '[numbers][c.curb_type_id]'},
                    itemId: {val: 0, qsField: '[numbers][c.curb_id]'},
                    oldId: {val: 0, qsField: '[numbers][c.cdi_part_id_old]'},
                    newId: {val: 0, qsField: '[numbers][c.cdi_part_id_new]'},
                    distributorId: {val: 0, qsField: '[numbers][distributor_id]'},
                    companyId: {val: 0, qsField: '[numbers][d.company_id]', defaultVal: 0},
                    turretReady: {val: 0, qsField: '[isset][c.turret_ready]', defaultVal: 0},
                    conforming: {val: 0, qsField: '[isset][c.conforming]', defaultVal: 0},
                    hasEstimator: {val: 0, qsField: '[isset][curb_estimate_id]', defaultVal: 0},
                    hasFreight: {val: 0, qsField: '[isset][freight_cost]', defaultVal: 0},
                    remake: {val: 1, qsField: '[isnot][remake]', defaultVal: 1},
                    warehouse: {val: 1, qsField: '[cdi_warehouse]', defaultVal: 1},
                    labor: {val: 1, qsField: '[jc_filter][labor]', defaultVal: 1},
                    materials: {val: 1, qsField: '[jc_filter][materials]', defaultVal: 1},
                    freight: {val: 1, qsField: '[jc_filter][freight]', defaultVal: 1},
                    misc: {val: 0, qsField: '[jc_filter][misc]', defaultVal: 0},
                    cogs: {val: 1, qsField: '[jc_filter][cogs]', defaultVal: 1},
                    grossProfit: {val: 1, qsField: '[jc_filter][net]', defaultVal: 1},
                    estimatedLabor: {val: 0, qsField: '[jc_filter][est_labor]', defaultVal: 0},
                    estimatedFreight: {val: 0, qsField: '[jc_filter][est_freight]', defaultVal: 0},
                    estimatedMaterials: {val: 0, qsField: '[jc_filter][est_materials]', defaultVal: 0},
                    estimatedExtras: {val: 0, qsField: '[jc_filter][est_extras]', defaultVal: 0},
                    curbTypes: {val: 0, qsField: '[numbers][c.curb_type_id]'},
                    curbTypesCheck: {val: [], qsField: '[in][c.curb_type_id]', defaultVal: []}
                },
                groupByFields: {
                    tracker_id: {label: 'Order', checked: true},
                    curb_type: {label: 'Item Type', checked: false},
                    nomenclature: {label: 'Item', checked: true},
                    company_id: {label: 'Company', checked: false},
                    conforming: {label: 'Conforming', checked: false},
                    distributor_id: {label: 'Customer', checked: false}
                },
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.state.isGroupBy = true
            this.$appStore.setTitle(['Job Costing']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>